import React, { useEffect } from 'react';
import { getCredentials } from '../../services/vidcredentials';
import { TableSpecs } from './Credentials';
import { CredentialsContextTypes } from '../../contexts/CredentialsProviders/CredentialsContext';
import { useAccessToken } from '../../hooks/useRefreshToken';

interface UseCredentialsProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCredentials: React.Dispatch<React.SetStateAction<any[]>>;
  setTableSpecs: React.Dispatch<React.SetStateAction<TableSpecs>>;
  loadSelectCredentialTypes: () => Promise<void>;
  credentialsContextType?: CredentialsContextTypes;
}
export default function useCredentials({
  setCredentials,
  setTableSpecs,
  setIsLoading,
  loadSelectCredentialTypes,
  credentialsContextType,
}: UseCredentialsProps) {
  async function loadInitialData() {
    await loadCredentials(10, 1);
    await loadSelectCredentialTypes();
  }

  useAccessToken(loadInitialData);

  async function loadCredentials(
    rowsPerPage: number,
    page: number,
    filterQuery?: string,
  ) {
    setIsLoading(true);
    const data = await getCredentials(
      rowsPerPage,
      page,
      filterQuery,
      credentialsContextType,
    );
    if ('error' in data) {
      setIsLoading(false);
      return;
    }
    const { credentialsList, auditTrailsList, ...fetchedTableSpecs } = data;
    setTableSpecs(fetchedTableSpecs);
    setCredentials(auditTrailsList ?? credentialsList);
    setIsLoading(false);
  }

  return { loadCredentials };
}
